<!-- number-scatter -->
<template>
  <el-select
    class="number-scatter"
    v-model="selected"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    :filterable="filterable"
    :filter-method="filterMethod"
    :default-first-option="defaultFirstOption"
    :size="size"
    @change="handleValueChange"
    @clear="handleValueClear"
  >
    <el-option
      v-for="item in list"
      :label="item[labelKey]"
      :value="item[valueKey]"
      :key="item[valueKey]"
    />
  </el-select>
</template>

<script>
  import select from '../../mixins/select';
  import dictionaryType from '../../enum/dictionary-type';
  import { getDictionaries } from '../../api/sys/dictionary';

  export default {
    name: 'number-scatter',
    mixins: [select],
    mounted() {
      this.getNumbers();
    },
    methods: {
      async getNumbers() {
        const params = { type: dictionaryType.enum.numberScatter, pageSize: 0 };
        const res = await getDictionaries(params);

        if (!res) return;

        const { data: { list: numbers = [] } = {} } = res;

        this.list = numbers
          .map(item => {
            const { _id, name } = item;

            return {
              [this.labelKey]: name,
              [this.valueKey]: _id,
            };
          })
          .reverse();
      },
    },
  };
</script>
