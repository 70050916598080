<!-- follow-edit -->
<template>
  <el-dialog
    class="follow-edit"
    :visible.sync="open"
    :title="title"
    width="560px"
    :close-on-click-modal="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <el-form
      class="follow-edit-form"
      ref="form"
      v-loading="loading"
      :model="model"
      :rules="rules"
      size="small"
      inline
      label-width="85px"
      @submit.native.prevent
    >
      <el-form-item label="主播名称:" prop="name">
        <el-input v-model.trim="model.name" placeholder="请输入" clearable />
      </el-form-item>

      <el-form-item label="直播平台:" prop="platformId">
        <live-platform v-model="model.platformId" />
      </el-form-item>

      <el-form-item label="直播间号:" prop="roomNo">
        <el-input v-model.trim="model.roomNo" placeholder="请输入" clearable />
      </el-form-item>

      <el-form-item label="游戏版块:" prop="categoryId">
        <game-category v-model="model.categoryId" />
      </el-form-item>

      <el-form-item label="粉丝牌:" prop="fansId">
        <number-scatter v-model="model.fansId" />
      </el-form-item>

      <el-form-item label="贵宾数:" prop="vipId">
        <number-scatter v-model="model.vipId" />
      </el-form-item>

      <el-form-item label="热度:" prop="heatId">
        <number-scatter v-model="model.heatId" />
      </el-form-item>

      <el-form-item label="订阅量:" prop="subscriptionId">
        <number-scatter v-model="model.subscriptionId" />
      </el-form-item>
    </el-form>

    <template v-slot:footer>
      <div class="follow-edit-operate">
        <el-button size="small" @click="handleOperateCancelClick"
          >取消
        </el-button>

        <el-button
          type="primary"
          size="small"
          :loading="saving"
          @click="handleOperateOkClick"
          >确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import { mapState } from 'vuex';
  import LivePlatform from '../../../../components/live-platform';
  import GameCategory from '../../../../components/game-category';
  import NumberScatter from '../../../../components/number-scatter';
  import {
    getFollow,
    addFollow,
    updateFollow,
  } from '../../../../api/anchor/follow';

  export default {
    name: 'follow-edit',
    components: {
      LivePlatform,
      GameCategory,
      NumberScatter,
    },
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        // 当前编辑的跟进对象
        follow: null,
        model: null,
        rules: {
          name: [{ required: true, message: '请填写', trigger: 'change' }],
          platformId: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          roomNo: [{ required: true, message: '请填写', trigger: 'change' }],
          categoryId: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
          fansId: [{ required: true, message: '请选择', trigger: 'change' }],
          vipId: [{ required: true, message: '请选择', trigger: 'change' }],
          heatId: [{ required: true, message: '请选择', trigger: 'change' }],
          subscriptionId: [
            { required: true, message: '请选择', trigger: 'change' },
          ],
        },
        loading: false,
        saving: false,
      };
    },
    computed: {
      ...mapState('anchor/follow', ['followId']),

      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 是否是编辑
      isEdit() {
        return !!this.followId;
      },
      // 对话框标题
      title() {
        return `${this.isEdit ? '编辑' : '新增'}跟进`;
      },
    },
    created() {
      this.initModel();
    },
    methods: {
      // 初始化模型
      initModel(data = {}) {
        const {
          anchorId = '',
          anchorName = '',
          platformId = '',
          roomNo = '',
          categoryId = '',
          fansId = '',
          vipId = '',
          heatId = '',
          subscriptionId = '',
        } = data || {};

        this.model = {
          anchorId,
          name: anchorName,
          platformId,
          roomNo,
          categoryId,
          fansId,
          vipId,
          heatId,
          subscriptionId,
        };
      },
      // 校验模型
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      // 重置模型
      resetModel() {
        this.initModel();
        this.$refs.form.resetFields();
      },
      // 保存模型
      async saveModel() {
        const params = {
          ...this.model,
          id: this.followId,
        };
        const method = this.isEdit ? updateFollow : addFollow;
        const res = await method(params);

        return !!res;
      },
      // 获取跟进对象详情
      async getFollow() {
        const res = await getFollow(this.followId);

        if (!res) return false;

        this.follow = res.data;

        return true;
      },
      // 对话框打开
      async handleDialogOpen() {
        if (!this.isEdit) return;

        this.loading = true;

        const success = await this.getFollow();

        this.loading = false;

        if (!success) return;

        this.initModel(this.follow);
      },
      // 对话框关闭完成
      handleDialogClosed() {
        this.follow = null;

        this.resetModel();
      },
      // 对话框取消按钮单击
      handleOperateCancelClick() {
        this.open = false;
      },
      // 对话框确定按钮单击
      async handleOperateOkClick() {
        const isValid = await this.validateModel();

        if (!isValid) return;

        this.saving = true;

        const success = await this.saveModel();

        this.saving = false;

        if (!success) return;

        this.open = false;
        this.$emit('on-ok');
      },
    },
  };
</script>

<style scoped lang="scss">
  .follow-edit {
    .follow-edit-form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      /deep/ .el-form-item {
        display: inline-flex;
        width: 49%;
        margin-right: 0;

        .el-form-item__label {
          flex-shrink: 0;
        }

        .el-form-item__content {
          flex-grow: 1;
        }
      }

      .el-select,
      .el-input {
        width: 100%;
      }
    }
  }
</style>
